import { DataService } from "@/utils/dataService";


async function getAllUser(params){
    return await DataService.get('adminUsers',params)
}
async function getAllFollowers(params){
    return await DataService.get('admin/followersList',params)

}

async function getAllFollowings(params){
    return await DataService.get('admin/followingList',params)

}

async function createVendor(data){
    return await DataService.post('vendor/create',data)
}

async function loadAvailableTribes(){
    return await DataService.get('admin/tribes/notAssigned')

}

async function getAllVendor(){
    return await DataService.get('vendor/getAll')
    
}

async function deleteVendor(id){
    return await DataService.post('vendor/delete',{vendorId:id})
    
}
async function removeTribeFromVendor(id){
    return await DataService.post('vendor/removeTribe',{vendorId:id})
    
}

async function updateVendor(data){
    return await DataService.post('vendor/update',data)
    
}
export {
    getAllUser,
    getAllFollowers,
    getAllFollowings,
    createVendor,
    loadAvailableTribes,
    getAllVendor,
    deleteVendor,
    removeTribeFromVendor,
    updateVendor
}