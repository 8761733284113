<script>
import {getAllFollowers}from "@/services/api/user"
import { userMethods } from "@/state/helpers";

/**
 * Advanced table component
 */
export default {
  data() {
    return {
      tableData: [],
      title: "",
      items: [
        {
          text: "Users",
          href: "/",
        },
        {
          text: "Normal",
          active: true,
        },
      ],
      rows: 0,
      currentPage: 1,
      currentUser:null,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],

      fields: [
        { key: "user", sortable: false, label: "User" },
        { key: "nomadic", sortable: false, label: "Nomad" },
        { key: "Contact", sortable: false },
      ],
      dataLoading: false,
    };
  },
  computed: {

  },
  mounted() {

  },
  created() {
    this.getFollowersUsers();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    ...userMethods,
    getFollowersUsers() {
      this.dataLoading = true;
      this.tableData = []
      getAllFollowers({ userId:this.$store.state.users.seleted._id,page: this.currentPage, perPage: this.perPage, role: "Normal" })
        .then((res) => {
          this.tableData = res.data.followers;
          this.rows= res.data.count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log | "Loaded";
        });
    },
    pageChange(val){
      this.currentPage = val;
      this.getFollowersUsers();

    },
    perPageChange(){
     this.getFollowersUsers();

    }
  },
};
</script>

<template>
<div>
  

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="no-data" v-if="!dataLoading&&!rows">
               No Follower
            </div>
            <div class="table-responsive mb-0" style="min-height: 70vh">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :busy="dataLoading"
                :hover="true"
               thead-class="d-none"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="pl-4">Loading...</strong>
                  </div>
                </template>
                <template #cell(following)="data">
                  {{ data.item.following.length }}
                </template>
                <template #cell(user)="data">
                  <b-avatar
                    rounded
                    :src="data.item.profile_image"
                    size="4rem"
                  ></b-avatar
                  >
                  <div style="font-weight:800">
                       {{ data.item.fullname }}
                  </div>
               
                </template>
                <template #cell(followers)="data">
                  {{ data.item.followers.length }}
                </template>
                <template #cell(contact)="data">
                  <strong>Email : </strong> {{ data.item.email }} <br />
                  <strong>Mobile : </strong> {{ data.item.phone_no }}
                </template>
                <template #cell(nomadic)="data">
                  <strong>ID: </strong>{{ data.item.nomadic_id }} <br />
                  <strong>Challenge: </strong>{{ data.item.challenge }} <br />
                  <strong>Nomadic Type: </strong>{{ data.item.nomad_type }}
                  <br />
                  <strong>Nomadic Level: </strong>{{ data.item.nomad_level }}
                  <br />
                </template>
                <template #cell(createdAt)="data">
                  {{ moment(data.item.createdAt).format("LLL") }}
                </template>
                <template #cell(updatedAt)="data">
                  {{ moment(data.item.updatedAt).format("LLL") }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="rows">
      <div class="col-sm-5">
        <div class="float-left">
               <label class="d-inline-flex align-items-center" style="min-width:45px">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChange"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>    
      </div>
      <div class="col-sm-7">
        <div class="float-right">
          <ul class="pagination pagination-rounded mb-4">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="pageChange"
            ></b-pagination>
          </ul>
          
        </div>
      </div>
    </div>
</div>

</template>
<style scoped>
.row:hover{
  cursor: pointer;
}
.no-data{
  text-align: center;
  padding-top: 14px;
  font-size:16px;
  font-weight: 600;
}
</style>